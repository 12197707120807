import React, { useCallback } from 'react';

import { QBadge, QBox, QFlex, QIcon, QTag, QText } from '@qualio/ui-components';
import { QTagProps } from '@qualio/ui-components/lib/QAtoms/Tag/Tag';

import { ClickPropagationBarrier } from '../ClickPropogateBarrier/ClickPropogateBarrier';
import { DisplayElement, DisplayElementBody, DisplayElementHeader } from '../DisplayElement';
import { SearchAndSelectElementType } from '../SearchAndSelectElements/SearchAndSelectElement';

export type SelectedElementStatus = Pick<QTagProps, 'variantColor'> & { label: string };

const DESIGN_ELEMENT_WARNING_TOOLTIP =
  'This design element has changed since being added to this DCC. Remove this element from the DCC and re-add to update to latest version.';

const DesignElementDisplay: React.FC<{
  element: SearchAndSelectElementType;
  handleSelectedElementOnClick?: (designElementId: string, designElementVersion: string) => void;
  warningText?: string;
}> = ({ element, warningText = DESIGN_ELEMENT_WARNING_TOOLTIP, handleSelectedElementOnClick, children }) => {
  const handleClickOnDesignElementCode = useCallback(
    (e: React.MouseEvent) => {
      const designElementId = e.currentTarget.getAttribute('target-id') as string;
      const designElementVersion = e.currentTarget.getAttribute('target-version') as string;
      if (handleSelectedElementOnClick) {
        handleSelectedElementOnClick(designElementId, designElementVersion);
      }
    },
    [handleSelectedElementOnClick],
  );

  return (
    <DisplayElement
      onClick={handleClickOnDesignElementCode}
      target-version={element.item.version}
      target-id={element.id}
      cursor={handleSelectedElementOnClick ? 'pointer' : undefined}
      title={element.showWarning ? warningText : undefined}
    >
      <DisplayElementHeader>
        <QBox width="90px" data-cy={`selected-item-title-${element.id}`}>
          {element.showTitleInBadge ? (
            <QBadge data-cy={`selected-item-title-${element.id}-badge`}>{element.title}</QBadge>
          ) : (
            element.title
          )}
        </QBox>
        {element.selectedItemLeftStatus && (
          <QBox mt="1px" mb="1px" width="90px" data-cy={`selected-item-status-left-${element.id}`}>
            <QTag variantColor={element.selectedItemLeftStatus.variantColor}>
              {element.selectedItemLeftStatus.label}
            </QTag>
          </QBox>
        )}
      </DisplayElementHeader>
      <DisplayElementBody style={{ width: 'calc(100% - 180px)' }} justify="space-between">
        <QBox width="60%">
          {element.selectedItemBody && (
            <QText data-cy={`selected-item-body-${element.id}`} textOverflow="ellipsis" noOfLines={1}>
              {element.selectedItemBody}
            </QText>
          )}
        </QBox>
        <QFlex justifyContent={'space-between'} columnGap={2} alignItems="center">
          {element.showWarning && (
            <QIcon
              data-cy={`selected-item-warning-icon-${element.id}`}
              iconName={'AlertTriangle'}
              color="gray.500"
              size="xs"
            />
          )}
          {element.selectedItemRightStatus && (
            <QTag
              data-cy={`selected-item-status-right-${element.id}`}
              variantColor={element.selectedItemRightStatus.variantColor}
            >
              <QText textOverflow="ellipsis" noOfLines={1}>
                {element.selectedItemRightStatus.label}
              </QText>
            </QTag>
          )}
          <ClickPropagationBarrier>{children}</ClickPropagationBarrier>
        </QFlex>
      </DisplayElementBody>
    </DisplayElement>
  );
};

export default DesignElementDisplay;
