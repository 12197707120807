import React from 'react';

import { QEmptyState } from '@qualio/ui-components';

export type ProductEmptyStateProps = {
  canManageProduct: boolean;
  onClick: () => void;
};

export const ProductEmptyState: React.FC<ProductEmptyStateProps> = ({ canManageProduct, onClick }) => (
  <QEmptyState
    title="Your products will appear here"
    subtitle="Design Controls is the all-in-one tool to manage quality compliance in product design."
    primaryButtonProps={
      canManageProduct
        ? ({
            isDisabled: false,
            isLoading: false,
            label: 'Create Product',
            onClick,
            'data-cy': 'create-product',
          } as any)
        : undefined
    }
  />
);
