import * as z from 'zod';

import {
  allRequirements,
  BaseQualityItem,
  baseQualityItem,
  DesignElementType,
  relatedElement,
} from './baseQualityItem';
import { AttachmentWithId, AttachmentWithIdType } from './generatePresignedUrl';
import { BaseQualityItemTrace } from './qualityItemTrace';

export const productRequirement = baseQualityItem
  .merge(z.object({ description: z.string().max(150_000, 'Description is too long').optional() }))
  .merge(relatedElement)
  .merge(z.object({ attachments: z.array(AttachmentWithId).optional() }))
  .strict();

export const formRequirement = productRequirement.pick({
  title: true,
  type: true,
  description: true,
  statusLabel: true,
  requirements: true,
  testCases: true,
  attachments: true,
});

const FormRelatedElement = z.object({
  code: z.string().min(1),
  label: z.string().min(1),
  value: z.string().min(1),
});

export const ProductRequirementFormRelatedElements = z.object({
  req1: z.array(FormRelatedElement).optional(),
  req2: z.array(FormRelatedElement).optional(),
  req3: z.array(FormRelatedElement).optional(),
  testCase: z.array(FormRelatedElement).optional(),
  testCase1: z.array(FormRelatedElement).optional(),
  testCase2: z.array(FormRelatedElement).optional(),
  testCase3: z.array(FormRelatedElement).optional(),
});

export type FormRelatedElementType = z.infer<typeof FormRelatedElement>;
export type ProductRequirementFormRelatedElementsType = z.infer<typeof ProductRequirementFormRelatedElements>;

export const ProductRequirementForm = z
  .object({
    statusLabel: z.object({
      value: z.string().min(1),
      label: z.string().min(1, { message: 'You must select a status' }),
    }),
    type: z.object({
      prefix: z.string().min(1),
      title: z.string().min(1),
      type: productRequirement.shape.type,
    }),
  })
  .merge(ProductRequirementFormRelatedElements)
  .merge(
    productRequirement.pick({
      title: true,
      description: true,
    }),
  );

export type ProductRequirementFormType = z.infer<typeof ProductRequirementForm>;

export type FormRequirement = z.infer<typeof formRequirement>;
export type Requirement = z.infer<typeof productRequirement>;

export type RequirementWithAttachment = BaseQualityItemTrace & { attachments: Array<AttachmentWithIdType> };

export function isRequirement(type: DesignElementType): boolean {
  return allRequirements.includes(type);
}

export const isRequirementWithAttachments = (item?: BaseQualityItem): item is RequirementWithAttachment => {
  return item !== undefined && isRequirement(item.type) && 'attachments' in item;
};
