import { DesignElementType } from '../types/baseQualityItem';
import { QualityTypeConfigWithType } from '../types/qualityTypeConfig';
import { RequirementConfigurationType } from '../types/requirementConfiguration';
import { TestCaseConfigurationType } from '../types/testCaseConfiguration';

export const mapBaseQualityTypeToRequirement = (
  baseConfig: QualityTypeConfigWithType[],
): RequirementConfigurationType => ({
  items: {
    req1: mapBaseQualityTypeBaseQualityConfig(
      baseConfig.find((item) => item.type === 'req1'),
      true,
    ) as RequirementConfigurationType['items']['req1'],
    req2: mapBaseQualityTypeBaseQualityConfig(baseConfig.find((item) => item.type === 'req2')),
    req3: mapBaseQualityTypeBaseQualityConfig(baseConfig.find((item) => item.type === 'req3')),
  },
});

export const mapBaseQualityTypeToTestCases = (baseConfig: QualityTypeConfigWithType[]): TestCaseConfigurationType => ({
  testCases: {
    testCase1: mapBaseQualityTypeBaseQualityConfig(
      baseConfig.find((item) => item.type === DesignElementType.TEST_CASE_1),
    ),
    testCase2: mapBaseQualityTypeBaseQualityConfig(
      baseConfig.find((item) => item.type === DesignElementType.TEST_CASE_2),
    ),
    testCase3: mapBaseQualityTypeBaseQualityConfig(
      baseConfig.find((item) => item.type === DesignElementType.TEST_CASE_3),
    ),
  },
});

const mapBaseQualityTypeBaseQualityConfig = (config: QualityTypeConfigWithType | undefined, emptyDefault = false) => {
  if (emptyDefault && !config) {
    return {
      prefix: '',
      label: '',
      statuses: [{ label: '' }],
    };
  }

  if (!config) {
    return;
  }

  return {
    prefix: config.codePrefix || '',
    label: config.label,
    statuses: config.workflow.states.map((item) => ({ label: item.name })),
  };
};
