import React, { useCallback } from 'react';

import { QBox, QEmptyState } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import { UserPermissions } from '../../components';
import hasPermission from '../../components/UserGroupToggle/lib/hasPermission';
import { useUserGroups } from '../../context/CurrentUserContext';
import { RiskSubType } from '../../types/risk';

export type RiskEmptyStateProps = {
  productId: string;
  riskSubType: RiskSubType;
};

export const RiskEmptyState: React.FC<RiskEmptyStateProps> = ({ productId, riskSubType }) => {
  const navigate = useNavigate();
  const [userGroups] = useUserGroups();
  const canCreateDesignElement = hasPermission(userGroups, UserPermissions.CREATE_DESIGN_ELEMENTS);

  const onClick = useCallback(() => {
    const createPath =
      riskSubType === RiskSubType.ISO ? `/product/${productId}/risk/add` : `/product/${productId}/risk/add/fmea`;
    navigate(createPath);
  }, [navigate, riskSubType, productId]);

  return (
    <QBox data-cy={`risk-empty-state-${riskSubType}`}>
      <QEmptyState
        title={'Start doing Product Design Controls the smart way'}
        subtitle={'Add your risks'}
        primaryButtonProps={
          canCreateDesignElement
            ? ({ label: 'Create Risk', onClick, 'data-cy': 'create-risk-button' } as any)
            : undefined
        }
      />
    </QBox>
  );
};
