import { z, ZodEffects, ZodTypeAny } from 'zod';

import { baseQualityItem, BaseQualityItem, DesignElementType } from './baseQualityItem';
import { getPropMaxLengthExceededMessage, getPropShouldNotBeEmptyMessage } from './validationError';

export type RiskAssessment = {
  probability: string;
  severity: string;
  riskLevel?: string;
};

export type FMEARiskAssessment = {
  severity: number;
  occurrence: number;
  detectability: number;
};

export type RiskControl = {
  mitigation: string;
};

export type Risk = BaseQualityItem & {
  hazard: string;
  foreseeableUseMisuse?: string;
  hazardSituation: string;
  harm: string;
  preMitigation: RiskAssessment;
  postMitigation?: RiskAssessment;
  riskControl?: RiskControl;
  subType: RiskSubType.ISO;
};

export enum RiskSubType {
  ISO = 'iso',
  FMEA = 'fmea',
}

export type FMEARisk = BaseQualityItem & {
  failureMode: string;
  failureModeEffects: string;
  failureCauses: string;
  evaluationMethod: string;
  riskControl?: RiskControl;
  preMitigation: FMEARiskAssessment;
  postMitigation?: FMEARiskAssessment;
  subType: RiskSubType.FMEA;
};

const RiskControlVal = z.object({
  mitigation: z
    .string()
    .min(1, getPropShouldNotBeEmptyMessage('Mitigation'))
    .max(1000, getPropMaxLengthExceededMessage('Mitigation')),
});

export const getISOAssessmentMessage = (prop: string): string => `Select a value for ${prop}`;
export const getFMEAAssessmentMessage = (): string => `Select a value for risk score`;

const ISOAssessment = z.object({
  probability: z.string().min(1, getISOAssessmentMessage('risk probability')),
  severity: z.string().min(1, getISOAssessmentMessage('risk severity')),
  riskLevel: z.string().min(1).optional(),
});

export const FMEARiskScoreSchema = z.preprocess((val) => {
  return typeof val === 'string' ? parseInt(val, 10) : val;
}, z.number().min(1, getFMEAAssessmentMessage()));

export const NullToEmptyStringSchema = (
  schema: ZodTypeAny,
): ZodEffects<ZodTypeAny, string | undefined, string | undefined> =>
  z.preprocess((val) => {
    return val === null ? '' : val;
  }, schema) as ZodEffects<ZodTypeAny, string | undefined, string | undefined>;

const FMEARiskAssessmentSchema = z.object({
  severity: FMEARiskScoreSchema,
  occurrence: FMEARiskScoreSchema,
  detectability: FMEARiskScoreSchema,
});

const BaseRisk = baseQualityItem.merge(
  z.object({
    riskControl: RiskControlVal.optional(),
    sourceItems: z.array(z.object({ id: z.string() })).optional(),
    mitigationItems: z.array(z.object({ id: z.string() })).optional(),
  }),
);

const ISORiskBase = z.object({
  hazard: z
    .string()
    .min(1, getPropShouldNotBeEmptyMessage('Hazard'))
    .max(1000, getPropMaxLengthExceededMessage('Hazard')),
  foreseeableUseMisuse: NullToEmptyStringSchema(
    z.string().max(1000, getPropMaxLengthExceededMessage('Reasonably forseeable use/misuse')).optional(),
  ),
  hazardSituation: z
    .string()
    .min(1, getPropShouldNotBeEmptyMessage('Hazardous situation'))
    .max(1000, getPropMaxLengthExceededMessage('Hazardous situation')),
  harm: z.string().min(1, getPropShouldNotBeEmptyMessage('Harm')).max(1000, getPropMaxLengthExceededMessage('Harm')),
  subType: z.literal('iso').optional(),
  preMitigation: ISOAssessment,
  postMitigation: ISOAssessment.optional(),
});

export const ISORiskRequest = BaseRisk.pick({
  title: true,
  riskControl: true,
  statusLabel: true,
  sourceItems: true,
  mitigationItems: true,
}).merge(ISORiskBase);

const FMEARiskBase = z.object({
  subType: z.literal('fmea').optional(),
  failureMode: z
    .string()
    .min(1, getPropShouldNotBeEmptyMessage('Failure mode'))
    .max(1000, getPropMaxLengthExceededMessage('Failure mode')),
  failureModeEffects: z
    .string()
    .min(1, getPropShouldNotBeEmptyMessage('Effects of failure mode'))
    .max(1000, getPropMaxLengthExceededMessage('Effects of failure mode')),
  failureCauses: z
    .string()
    .min(1, getPropShouldNotBeEmptyMessage('Causes of failure'))
    .max(1000, getPropMaxLengthExceededMessage('Causes of failure')),
  evaluationMethod: z
    .string()
    .min(1, getPropShouldNotBeEmptyMessage('Current controls / evaluation method'))
    .max(1000, getPropMaxLengthExceededMessage('Current controls / evaluation method')),
  preMitigation: FMEARiskAssessmentSchema,
  postMitigation: FMEARiskAssessmentSchema.optional(),
});

export type ISORiskRequestType = z.infer<typeof ISORiskRequest>;

export const ISORisk = BaseRisk.merge(ISORiskBase).refine(
  (data) => data.postMitigation === undefined || data.riskControl !== undefined,
  {
    message: 'Risk can not have a pre mitigation assessment without a risk control',
  },
);

export type ISORiskType = z.infer<typeof ISORisk>;

export const FMEARiskRequest = BaseRisk.pick({
  title: true,
  riskControl: true,
  statusLabel: true,
  sourceItems: true,
  mitigationItems: true,
}).merge(FMEARiskBase);

export type FMEARiskRequestType = z.infer<typeof FMEARiskRequest>;

export function isRisk(type: DesignElementType): boolean {
  return DesignElementType.RISK === type;
}

export function isISORisk(item: Risk | FMEARisk): item is Risk {
  return !item.subType || item.subType === RiskSubType.ISO;
}

export function isFMEARisk(item: Risk | FMEARisk): item is FMEARisk {
  return item.subType === RiskSubType.FMEA;
}
