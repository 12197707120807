import React, { useCallback, useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QBox, QButton, QDivider, QFormControl, QInput, QStack, QTag, QText, QTextarea } from '@qualio/ui-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { useFmeaRiskCalculateScore } from '../../hooks/risk/useFmeaRiskCalculateScore';
import { handleFormErrors } from '../../lib/formValidationUtilities';
import { DesignElementType } from '../../types/baseQualityItem';
import { FMEARiskRequest, FMEARiskRequestType, RiskSubType } from '../../types/risk';
import { fmeaRiskScoreColor } from '../../views_new/Review/List/statusLabelAndColorTag';
import { FMEARiskScoreSelect } from './components/FMEARiskScoreSelect';
import { RiskSourceMitigationItemSelect } from './components/RiskSourceMitigationItemSelect';
import { RiskStatusLabelSelect } from './components/RiskStatusLabelSelect';
import { RiskFormProps } from './type';

const defaultValue: FMEARiskRequestType = {
  title: '',
  statusLabel: '',
  failureMode: '',
  failureModeEffects: '',
  failureCauses: '',
  evaluationMethod: '',
  preMitigation: {
    severity: 0,
    occurrence: 0,
    detectability: 0,
  },
  riskControl: { mitigation: '' },
  postMitigation: {
    severity: 0,
    occurrence: 0,
    detectability: 0,
  },
  sourceItems: [],
  mitigationItems: [],
};

export const FMEAForm: React.FC<RiskFormProps<FMEARiskRequestType>> = ({
  isSubmitting,
  configs,
  onClose,
  onSubmit,
  riskConfig,
  autoCompleteOptions,
  initialValue = defaultValue,
  itemId,
}) => {
  const severityOccurrenceDetectabilityOptions = useMemo(
    () => [...Array(riskConfig.assessment.fmea?.scaleMaxValue).keys()].map((i) => i + 1),
    [riskConfig.assessment.fmea?.scaleMaxValue],
  );

  const methods = useForm<FMEARiskRequestType>({
    mode: 'onChange',
    defaultValues: initialValue,
    reValidateMode: 'onChange',
    resolver: zodResolver(FMEARiskRequest),
  });
  const {
    formState: { errors },
    setError,
    watch,
  } = methods;

  const preMitigationWatchFields = watch([
    'preMitigation.severity',
    'preMitigation.occurrence',
    'preMitigation.detectability',
  ]) as Record<string, number>;
  const postMitigationWatchFields = watch([
    'postMitigation.severity',
    'postMitigation.occurrence',
    'postMitigation.detectability',
  ]) as Record<string, number>;

  const preMitigationRiskScore = useFmeaRiskCalculateScore('preMitigation', preMitigationWatchFields);
  const showPostMitigationSections =
    preMitigationRiskScore &&
    riskConfig.assessment.fmea &&
    preMitigationRiskScore >= riskConfig.assessment.fmea?.mitigationThreshold;
  const postMitigationRiskScore = useFmeaRiskCalculateScore('postMitigation', postMitigationWatchFields);

  const handleSubmitClick = useCallback(
    (payload: FMEARiskRequestType) => {
      const sourceItemIds = payload.sourceItems?.map((item) => item.id) ?? [];
      const mitigationItemIds = payload.mitigationItems?.map((item) => item.id) ?? [];

      onSubmit(
        {
          ...payload,
          id: itemId,
          subType: RiskSubType.FMEA,
          type: DesignElementType.RISK,
          sourceItems: sourceItemIds,
          mitigationItems: mitigationItemIds,
        } as any,
        {
          onSuccess: () => undefined,
          onError: handleFormErrors(setError),
        },
      );
    },
    [onSubmit, itemId, setError],
  );

  return (
    <QBox margin="auto" data-cy="fmea-risk-form">
      <FormProvider {...methods}>
        <QFormControl
          label="Title"
          isInvalid={!!errors.title}
          error={errors.title?.message}
          helper="&nbsp;"
          id={'title'}
        >
          <Controller
            name={'title'}
            render={({ onChange, value }) => <QInput onChange={onChange} value={value} data-cy="title-input" />}
          />
        </QFormControl>
        <QFormControl
          label="Status"
          isInvalid={!!errors.statusLabel}
          error={errors.statusLabel?.message}
          helper="&nbsp;"
          id={'statusLabel'}
        >
          <Controller
            name={'statusLabel'}
            render={({ onChange, value }) => (
              <RiskStatusLabelSelect value={value} options={riskConfig.workflow.states} onChange={onChange} />
            )}
          />
        </QFormControl>
        <QDivider mb="30px" />
        <QBox mb={2}>
          <QText weight={'semibold'} fontSize="md">
            Initial Assessment
          </QText>
        </QBox>
        <QFormControl
          label="Failure Mode"
          isInvalid={!!errors.failureMode}
          error={errors.failureMode?.message}
          helper="&nbsp;"
          id={'failureMode'}
        >
          <Controller
            name={'failureMode'}
            render={({ onChange, value }) => (
              <QTextarea onChange={onChange} value={value} data-cy="failureMode-input" />
            )}
          />
        </QFormControl>
        <QFormControl
          label="Effects of Failure Mode"
          isInvalid={!!errors.failureModeEffects}
          error={errors.failureModeEffects?.message}
          helper="&nbsp;"
          id={'failureModeEffects'}
        >
          <Controller
            name={'failureModeEffects'}
            render={({ onChange, value }) => (
              <QTextarea onChange={onChange} value={value} data-cy="failureModeEffects-input" />
            )}
          />
        </QFormControl>
        <QFormControl
          label="Severity"
          isInvalid={!!errors.preMitigation?.severity}
          error={errors.preMitigation?.severity?.message}
          helper="&nbsp;"
          id={'preMitigation.severity'}
        >
          <Controller
            name={'preMitigation.severity'}
            render={({ onChange, value }) => (
              <FMEARiskScoreSelect
                onChange={onChange}
                value={value}
                options={severityOccurrenceDetectabilityOptions}
                name={'preMitigation.severity-input'}
                placeholder={'Initial severity score'}
              />
            )}
          />
        </QFormControl>
        <QFormControl
          label="Causes of Failure"
          isInvalid={!!errors.failureCauses}
          error={errors.failureCauses?.message}
          helper="&nbsp;"
          id={'failureCauses'}
        >
          <Controller
            name={'failureCauses'}
            render={({ onChange, value }) => (
              <QTextarea onChange={onChange} value={value} data-cy="failureCauses-input" />
            )}
          />
        </QFormControl>
        <QFormControl
          label="Occurrence"
          isInvalid={!!errors.preMitigation?.occurrence}
          error={errors.preMitigation?.occurrence?.message}
          helper="&nbsp;"
          id={'preMitigation.occurrence'}
        >
          <Controller
            name={'preMitigation.occurrence'}
            render={({ onChange, value }) => (
              <FMEARiskScoreSelect
                onChange={onChange}
                value={value}
                options={severityOccurrenceDetectabilityOptions}
                name={'preMitigation.occurrence-input'}
                placeholder={'Initial occurrence score'}
              />
            )}
          />
        </QFormControl>
        <QFormControl
          label="Current Controls / Evaluation Method"
          isInvalid={!!errors.evaluationMethod}
          error={errors.evaluationMethod?.message}
          helper="&nbsp;"
          id={'evaluationMethod'}
        >
          <Controller
            name={'evaluationMethod'}
            render={({ onChange, value }) => (
              <QTextarea onChange={onChange} value={value} data-cy="evaluationMethod-input" />
            )}
          />
        </QFormControl>
        <QFormControl
          label="Detectability"
          isInvalid={!!errors.preMitigation?.detectability}
          error={errors.preMitigation?.detectability?.message}
          helper="&nbsp;"
          id={'preMitigation.detectability'}
        >
          <Controller
            name={'preMitigation.detectability'}
            render={({ onChange, value }) => (
              <FMEARiskScoreSelect
                onChange={onChange}
                value={value}
                options={severityOccurrenceDetectabilityOptions}
                name={'preMitigation.detectability-input'}
                placeholder={'Initial detectability score'}
              />
            )}
          />
        </QFormControl>
        {preMitigationRiskScore && (
          <QBox mb="30px" data-cy="pre-mitigation-risk-score">
            <QBox mb="5px">
              <QText weight={'semibold'} fontSize="md">
                Risk Score
              </QText>
            </QBox>
            <QTag
              variantColor={fmeaRiskScoreColor(preMitigationRiskScore, riskConfig.assessment.fmea?.mitigationThreshold)}
            >
              {preMitigationRiskScore}
            </QTag>
          </QBox>
        )}
        <Controller
          name={'sourceItems'}
          defaultValue={initialValue.sourceItems ?? []}
          id={'sourceItems'}
          render={({ onChange, value }) => (
            <RiskSourceMitigationItemSelect
              title={'Impacted Design Elements'}
              titleVariant={true}
              name={'sourceItems'}
              placeholder={'Search design elements to add as source'}
              options={autoCompleteOptions}
              values={value}
              onChange={onChange}
              configs={configs}
            />
          )}
        />
        <QDivider mt="30px" mb="30px" />
        {showPostMitigationSections && (
          <>
            <QBox mb={2}>
              <QText weight={'semibold'} fontSize="md">
                Mitigation
              </QText>
            </QBox>
            <QFormControl
              label="Description"
              isInvalid={!!errors.riskControl?.mitigation}
              error={errors.riskControl?.mitigation?.message}
              helper="&nbsp;"
              id={'riskControl.mitigation'}
            >
              <Controller
                name={'riskControl.mitigation'}
                render={({ onChange, value }) => (
                  <QTextarea onChange={onChange} value={value} data-cy="riskControl.mitigation-input" />
                )}
              />
            </QFormControl>
            <Controller
              name={'mitigationItems'}
              defaultValue={initialValue.mitigationItems ?? []}
              id={'mitigationItems'}
              render={({ onChange, value }) => (
                <RiskSourceMitigationItemSelect
                  title={'Design Elements Associated With Mitigation'}
                  titleVariant={true}
                  name={'mitigationItems'}
                  placeholder={'Search design elements to add as mitigation'}
                  options={autoCompleteOptions}
                  values={value}
                  onChange={onChange}
                  configs={configs}
                />
              )}
            />
            <QDivider mt="30px" mb="30px" />
            <QBox mb={2}>
              <QText weight={'semibold'} fontSize="md">
                Final Assessment
              </QText>
            </QBox>
            <QFormControl
              label="Severity"
              isInvalid={!!errors.postMitigation?.severity}
              error={errors.postMitigation?.severity?.message}
              helper="&nbsp;"
              id={'postMitigation.severity'}
            >
              <Controller
                name={'postMitigation.severity'}
                render={({ onChange, value }) => (
                  <FMEARiskScoreSelect
                    onChange={onChange}
                    value={value}
                    options={severityOccurrenceDetectabilityOptions}
                    name={'postMitigation.severity-input'}
                    placeholder={'Final severity score'}
                  />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Occurrence"
              isInvalid={!!errors.postMitigation?.occurrence}
              error={errors.postMitigation?.occurrence?.message}
              helper="&nbsp;"
              id={'postMitigation.occurrence'}
            >
              <Controller
                name={'postMitigation.occurrence'}
                id={'title'}
                render={({ onChange, value }) => (
                  <FMEARiskScoreSelect
                    onChange={onChange}
                    value={value}
                    options={severityOccurrenceDetectabilityOptions}
                    name={'postMitigation.occurrence-input'}
                    placeholder={'Final occurrence score'}
                  />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Detectability"
              isInvalid={!!errors.postMitigation?.detectability}
              error={errors.postMitigation?.detectability?.message}
              helper="&nbsp;"
              id={'postMitigation.detectability'}
            >
              <Controller
                name={'postMitigation.detectability'}
                render={({ onChange, value }) => (
                  <FMEARiskScoreSelect
                    onChange={onChange}
                    value={value}
                    options={severityOccurrenceDetectabilityOptions}
                    name={'postMitigation.detectability-input'}
                    placeholder={'Final detectability score'}
                  />
                )}
              />
            </QFormControl>
            {postMitigationRiskScore && (
              <QBox mb="30px" data-cy="post-mitigation-risk-score">
                <QBox mb="5px">
                  <QText weight={'semibold'} fontSize="md">
                    Risk Score
                  </QText>
                </QBox>
                <QTag
                  variantColor={fmeaRiskScoreColor(
                    postMitigationRiskScore,
                    riskConfig.assessment.fmea?.mitigationThreshold,
                  )}
                >
                  {postMitigationRiskScore}
                </QTag>
              </QBox>
            )}
            <QDivider mb="30px" />
          </>
        )}
        <QStack spacing="20px" direction="row" justifyContent="flex-end">
          <QButton variant={'link'} onClick={onClose} data-cy="cancel-button" isDisabled={isSubmitting}>
            Cancel
          </QButton>
          <QButton
            variant={'solid'}
            data-cy="submit-button"
            onClick={methods.handleSubmit(handleSubmitClick)}
            isLoading={isSubmitting}
          >
            Save
          </QButton>
        </QStack>
      </FormProvider>
    </QBox>
  );
};
