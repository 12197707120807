declare global {
  const CKEditor5: any;
}

const documentEditorConfig: { [k: string]: any } = {
  plugins: [
    CKEditor5.autoformat.Autoformat,
    CKEditor5.basicStyles.Bold,
    CKEditor5.basicStyles.Italic,
    CKEditor5.basicStyles.Underline,
    CKEditor5.basicStyles.Subscript,
    CKEditor5.basicStyles.Superscript,
    CKEditor5.basicStyles.Strikethrough,
    CKEditor5.blockQuote.BlockQuote,
    CKEditor5.codeBlock.CodeBlock,
    CKEditor5.essentials.Essentials,
    CKEditor5.findAndReplace.FindAndReplace,
    CKEditor5.font.FontColor,
    CKEditor5.font.FontSize,
    CKEditor5.font.FontBackgroundColor,
    CKEditor5.alignment.Alignment,
    CKEditor5.heading.Heading,
    CKEditor5.horizontalLine.HorizontalLine,
    CKEditor5.removeFormat.RemoveFormat,
    CKEditor5.specialCharacters.SpecialCharacters,
    CKEditor5.image.Image,
    CKEditor5.image.ImageCaption,
    CKEditor5.image.ImageStyle,
    CKEditor5.image.ImageToolbar,
    CKEditor5.image.ImageUpload,
    CKEditor5.indent.Indent,
    CKEditor5.link.Link,
    CKEditor5.list.List,
    CKEditor5.mediaEmbed.MediaEmbed,
    CKEditor5.paragraph.Paragraph,
    CKEditor5.pasteFromOffice.PasteFromOffice,
    CKEditor5.table.Table,
    CKEditor5.table.TableToolbar,
  ],

  toolbar: {
    shouldNotGroupWhenFull: false,
    items: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      'fontSize',
      'fontColor',
      '|',
      'link',
      'findAndReplace',
      '|',
      'alignment',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      '|',
      'insertTable',
      'specialCharacters',
      'subscript',
      'superscript',
      '|',
      'codeBlock',
      'blockQuote',
      '|',
      'fontBackgroundColor',
      'strikethrough',
      '|',
      'horizontalLine',
      '|',
      'removeFormat',
    ],
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'toggleTableCaption',
      'tableProperties',
      'tableCellProperties',
    ],
  },
  image: {
    toolbar: [
      'toggleImageCaption',
      'linkImage',
      'imageTextAlternative',
      '|',
      'imageStyle:alignLeft',
      'imageStyle:alignRight',
      'imageStyle:alignBlockLeft',
      'imageStyle:alignBlockRight',
      'imageStyle:alignCenter',
    ],
  },
  licenseKey: process.env.REACT_APP_CKE_PREMIUM_FEATURES_LICENSE_KEY,
  indentBlock: {
    offset: 2,
    unit: 'em',
  },
  fontSize: {
    options: [
      {
        title: 'Small',
        model: '12px',
        upcastAlso: [],
        view: '',
      },
      {
        title: 'Normal',
        model: '13px',
        upcastAlso: [],
        view: '',
      },
      {
        title: 'Large',
        model: '19.6px',
        upcastAlso: [],
        view: '',
      },
    ],
  },
};

export const getDocumentEditorConfig = (companyId: number, smartlinkEverythingEnabled: boolean) => {
  const config = { ...documentEditorConfig };

  if (smartlinkEverythingEnabled) {
    config.plugins = [...config.plugins, CKEditor5.mention.Mention, CKEditor5.smartlink.QualioSmartlink];
    config.mention = CKEditor5.smartlink.QualioSmartlink.getMentionConfig(
      companyId,
      process.env.REACT_APP_GLOBAL_SEARCH_SERVICE_HOSTNAME,
    );
  }
  return config;
};
