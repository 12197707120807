import React, { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import productApi from '../../api/product';
import { Container, ProductInfoForm } from '../../components';
import BasicForm from '../../components/BasicForm/BasicForm';
import { withMessages } from '../../components/Messages';
import { useCompanyId } from '../../context/CurrentUserContext';
import { ProductParam } from '../../paramTypes';
import { Product, ProductConfiguration, productConfiguration } from '../../types/product';

const EditProduct: React.FC = () => {
  const company = useCompanyId();
  const { product } = useParams<ProductParam>() as ProductParam;
  const navigate = useNavigate();

  const { isLoading, data } = useQuery<Product>(`retrieve-product-${product}`, () =>
    productApi.fetch(company, product),
  );

  const { mutate, isLoading: isUpdatingProduct } = useMutation(
    (data: ProductConfiguration) => productApi.update(company, product, data),
    {
      onSuccess: () => navigate('/product'),
    },
  );

  const methods = useForm<ProductConfiguration>({
    defaultValues: undefined,
    mode: 'onChange',
    resolver: zodResolver(productConfiguration),
  });
  const {
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    if (!data) {
      return undefined;
    }

    setValue('name', data.name);
    setValue('type', data.type);
  }, [data, setValue]);

  const handleSubmit = async (productData: ProductConfiguration) => mutate(productData);

  return (
    <Container>
      <FormProvider {...methods}>
        <BasicForm
          title="Edit name and type"
          product={data as Product}
          isError={Object.keys(errors).length > 0}
          isLoading={isLoading}
          errorText="Please fill out all the form"
          cancelText="Cancel"
          confirmText="Save changes"
          confirmButtonLoading={isUpdatingProduct}
          onCancel={() => navigate('/product')}
          onConfirm={methods.handleSubmit(handleSubmit)}
        >
          <ProductInfoForm />
        </BasicForm>
      </FormProvider>
    </Container>
  );
};

export default withMessages(EditProduct);
