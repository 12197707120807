import * as z from 'zod';

import { Prefix } from '../context/qualityConfigTypes';
import { prefixDoesNotExist } from '../lib/prefixDoesNotExist';
import { BaseConfiguration } from './baseConfiguration';

const REQUIREMENT_UNIQUE_ERR_MSG = 'Prefix must be unique';

export const RequirementConfiguration = z.object({
  items: z.object({
    req1: BaseConfiguration,
    req2: BaseConfiguration.optional(),
    req3: BaseConfiguration.optional(),
  }),
});

export const requirementConfigurationSchemaProvider = (usedPrefixes: Prefix[], archivePrefixes: Array<string>): any => {
  const listOfUsedPrefixes = usedPrefixes.filter((prefix) => !prefix.type.startsWith('req'));

  return RequirementConfiguration.refine(
    (data: any) => {
      const prefixNotUsedOnForm = ![data.items.req2?.prefix, data.items.req3?.prefix].includes(data.items.req1.prefix);
      console.log('req1', prefixNotUsedOnForm, usedPrefixes, listOfUsedPrefixes);
      return (
        prefixNotUsedOnForm && prefixDoesNotExist(data.items.req1.prefix, 'req1', listOfUsedPrefixes, archivePrefixes)
      );
    },
    {
      message: REQUIREMENT_UNIQUE_ERR_MSG,
      path: ['items', 'req1', 'prefix'],
    },
  )
    .refine(
      (data: any) => {
        const prefixNotUsedOnForm =
          !data.items.req2?.prefix ||
          ![data.items.req1.prefix, data.items.req3?.prefix].includes(data.items.req2.prefix);
        console.log('req2', prefixNotUsedOnForm, usedPrefixes, listOfUsedPrefixes);
        return (
          prefixNotUsedOnForm &&
          prefixDoesNotExist(data.items.req2?.prefix, 'req2', listOfUsedPrefixes, archivePrefixes)
        );
      },
      {
        message: REQUIREMENT_UNIQUE_ERR_MSG,
        path: ['items', 'req2', 'prefix'],
      },
    )
    .refine(
      (data: any) => {
        const prefixNotUsedOnForm =
          !data.items.req3?.prefix ||
          ![data.items.req2?.prefix, data.items.req1.prefix].includes(data.items.req3.prefix);
        console.log('req3', prefixNotUsedOnForm, usedPrefixes, listOfUsedPrefixes);
        return (
          prefixNotUsedOnForm &&
          prefixDoesNotExist(data.items.req3?.prefix, 'req3', listOfUsedPrefixes, archivePrefixes)
        );
      },
      {
        message: REQUIREMENT_UNIQUE_ERR_MSG,
        path: ['items', 'req3', 'prefix'],
      },
    );
};

export type RequirementConfigurationType = z.infer<typeof RequirementConfiguration>;
