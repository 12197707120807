import React from 'react';

import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QualioQThemeProvider,
} from '@qualio/ui-components';

export type ConfirmationDetails = {
  title: string;
  message: React.ReactElement;
};
export type Props = {
  onConfirm: () => void;
  onClose: () => void;
  confirmationDetails?: ConfirmationDetails;
};
export const DeleteConfirmationModal: React.FC<Props> = ({ onConfirm, onClose, confirmationDetails }) => {
  const confirmDelete = () => confirmationDetails && onConfirm();

  return (
    <QualioQThemeProvider>
      <div data-cy="delete-confirmation-modal">
        <QModal isOpen={!!confirmationDetails} onClose={onClose} data-cy="delete-confirmation-modal">
          <QModalHeader>
            <span>{confirmationDetails && confirmationDetails.title}</span>
            <QCloseButton onClick={onClose} />
          </QModalHeader>
          <QModalBody>{confirmationDetails && confirmationDetails.message}</QModalBody>
          <QModalActions>
            <QButton variant="outline" onClick={onClose} data-cy="cancel-delete-btn">
              Cancel
            </QButton>
            <QButton isDestructive={true} onClick={confirmDelete} data-cy="confirm-delete-btn">
              Delete
            </QButton>
          </QModalActions>
        </QModal>
      </div>
    </QualioQThemeProvider>
  );
};
