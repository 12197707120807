import * as z from 'zod';

import { getPropShouldNotBeEmptyMessage } from './validationError';

export type TestCaseType =
  | DesignElementType.TEST_CASE_1
  | DesignElementType.TEST_CASE_2
  | DesignElementType.TEST_CASE_3;

export type RequirementsType = DesignElementType.REQ1 | DesignElementType.REQ2 | DesignElementType.REQ3;

export enum DesignElementType {
  RISK = 'risk',
  TEST_CASE = 'testCase', // deprecated
  REQ1 = 'req1',
  REQ2 = 'req2',
  REQ3 = 'req3',
  TEST_LOG = 'testLog', // deprecated
  DOCUMENT = 'document',
  TEST_CASE_1 = 'testCase1',
  TEST_CASE_2 = 'testCase2',
  TEST_CASE_3 = 'testCase3',
}

export const designElementType = z.nativeEnum(DesignElementType);

export type BaseQualityType = z.infer<typeof designElementType>;

export const allRequirements = [DesignElementType.REQ1, DesignElementType.REQ2, DesignElementType.REQ3];
export const allTestCases = [
  DesignElementType.TEST_CASE_1,
  DesignElementType.TEST_CASE_2,
  DesignElementType.TEST_CASE_3,
];

export const baseChangeControlStatus = z.union([
  z.literal('unstaged'),
  z.literal('staged'),
  z.literal('approved'),
  z.literal('not_approved'),
]);

export type BaseChangeControlStatus = z.infer<typeof baseChangeControlStatus>;

export enum QualityItemStatus {
  NEW = 'NEW',
  DELETED = 'DELETED',
  CHANGED = 'CHANGED',
}

export const qualityItemStatus = z.union([
  z.literal(QualityItemStatus.NEW),
  z.literal(QualityItemStatus.DELETED),
  z.literal(QualityItemStatus.CHANGED),
]);

export const baseQualityItem = z
  .object({
    id: z.string().uuid(),
    title: z.string().min(1, getPropShouldNotBeEmptyMessage('Title')).max(255, 'Title is too long'),
    created: z.string(),
    updated: z.string(),
    statusLabel: z.string().min(1, 'Select a status'),
    source: z.string(),
    url: z.string().url(),
    type: designElementType,
    subType: z.string().optional(),
    code: z.string().optional(),
    codeTitle: z.string().optional(),
    itemStatus: qualityItemStatus.optional(),
    changeControlStatus: baseChangeControlStatus,
    description: z.string().optional(),
  })
  .strict();

export const relatedElement = z
  .object({
    requirements: z.array(z.string()).optional(),
    testCases: z.array(z.string()).optional(),
  })
  .strict();

export type BaseQualityItem = z.infer<typeof baseQualityItem>;
