import React from 'react';

import { QFormControl, QInput, QStack } from '@qualio/ui-components';
import { Controller, useFormContext } from 'react-hook-form';

import { RadioGroup } from '../../components_new/RadioGroup/RadioGroup';
import { productType } from '../../types/product';

const ProductInfoForm: React.FC = () => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div data-cy="product-info-form">
      <QFormControl label="Product name *" isInvalid={!!errors.name} error={errors.name?.message}>
        <Controller
          name={'name'}
          render={({ onChange, value }) => <QInput value={value} onChange={onChange} data-cy={`product-name`} />}
        />
      </QFormControl>

      <QStack mb={5} mt={5}>
        <QFormControl label={'Type'} isInvalid={!!errors.type} error={errors.type?.message}>
          <Controller
            name={'type'}
            render={({ value, onChange, name }) => (
              <RadioGroup
                defaultValue={value}
                name={name}
                onChange={onChange}
                options={productType.options.map((option) => ({ label: option.value, value: option.value }))}
              />
            )}
          />
        </QFormControl>
      </QStack>
    </div>
  );
};

export default ProductInfoForm;
