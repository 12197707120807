export enum UserGroups {
  QUALITY = 'quality',
  NORMAL = 'normal',
  BASIC = 'basic',
}

export const userGroupList: Array<string> = Object.values(UserGroups);

export enum UserPermissions {
  CREATE_DESIGN_ELEMENTS = 'createDesignElements',
  EDIT_DESIGN_ELEMENTS = 'editDesignElements',
  DELETE_DESIGN_ELEMENTS = 'deleteDesignElements',
  CREATE_CHANGE_CONTROL = 'createChangeControl',
  EDIT_CHANGE_CONTROL = 'editChangeControl',
  DELETE_CHANGE_CONTROL = 'deleteChangeControl',
  APPROVE_CHANGE_CONTROL = 'approveChangeControl',
  CREATE_DESIGN_ELEMENT_POLICIES = 'createDesignElementPolicies',
  EDIT_DESIGN_ELEMENT_POLICIES = 'editDesignElementPolicies',
  DELETE_DESIGN_ELEMENT_POLICIES = 'deleteDesignElementPolicies',
  MANAGE_PRODUCT = 'createEditDeleteProduct',
}

export const normalUserPermissions = [
  UserPermissions.CREATE_DESIGN_ELEMENTS,
  UserPermissions.EDIT_DESIGN_ELEMENTS,
  UserPermissions.DELETE_DESIGN_ELEMENTS,
  UserPermissions.CREATE_CHANGE_CONTROL,
  UserPermissions.EDIT_CHANGE_CONTROL,
];

export const qualityUserPermissions = [
  ...normalUserPermissions,
  UserPermissions.DELETE_CHANGE_CONTROL,
  UserPermissions.APPROVE_CHANGE_CONTROL,
  UserPermissions.CREATE_DESIGN_ELEMENT_POLICIES,
  UserPermissions.EDIT_DESIGN_ELEMENT_POLICIES,
  UserPermissions.DELETE_DESIGN_ELEMENT_POLICIES,
  UserPermissions.MANAGE_PRODUCT,
];
